<template>
  <div class="mile-progress-staging">
    <div class="mile-progress-staging__inner">
      <transition name="mile-progress-staging-fade">
        <div v-show="isStageCompleted" class="mile-progress-staging__content">
          <img
            :src="images['completed']"
            :alt="WORDS.MILE_PROGRESS.COMPLETED" />
        </div>
      </transition>

      <transition name="mile-progress-staging-fade">
        <div v-show="isStageUp" class="mile-progress-staging__content">
          <img :src="images['stageup']" :alt="WORDS.STAGE_UP" />
          <div class="mile-progress-staging__content-button">
            <p class="c-lead4 c-lead4--white01 c-lead4--center">
              {{ WORDS.MILE_PROGRESS.COMPLETED_DESCRIPTION }}
            </p>
            <Button style-type="quaternary" fluid @click="onNextStage">
              {{ WORDS.MILE_PROGRESS.NEXT_BUTTON }}
            </Button>
          </div>
        </div>
      </transition>
    </div>
    <!-- /.mile-progress-staging__inner -->
  </div>
  <!-- /.mile-progress-staging -->
</template>

<script>
import { WORDS } from '@/constants/words';
import { defineComponent, ref, computed } from '@vue/composition-api';
export default defineComponent({
  props: {
    images: {
      type: Object,
      default: undefined
    }
  },

  setup: (props, context) => {
    const status = ref('completed');
    const isStageCompleted = computed(() => status.value === 'completed');
    const isStageUp = computed(() => status.value === 'stageup');
    const sleep = (ms = 100) =>
      new Promise((resolve) => setTimeout(resolve, ms));
    const onShow = async () => {
      if (isStageCompleted) {
        await sleep(3200);
        status.value = 'stageup';
      }
    };

    /**
     * 演出アニメーションを終える
     */
    const onNextStage = () => {
      context.emit('switch-staging', false);
    };

    onShow();
    return {
      WORDS,
      isStageUp,
      isStageCompleted,
      onNextStage
    };
  }
});
</script>

<style lang="scss" scoped>
@use '@/assets/scss/variables';
@use '@/assets/scss/mixin';

.mile-progress-staging {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &__inner {
    background-color: variables.$secondary01;
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: auto;
    }
  }

  &__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    &-button {
      padding: 24px 16px;
    }
  }
}

.mile-progress-staging-fade {
  &-enter-active,
  &-leave-active {
    transition: opacity ease 0.25s;
  }
  &-enter,
  &-leave-to {
    opacity: 0;
  }
}
</style>
