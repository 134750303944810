var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mile-progress-staging" }, [
    _c(
      "div",
      { staticClass: "mile-progress-staging__inner" },
      [
        _c("transition", { attrs: { name: "mile-progress-staging-fade" } }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isStageCompleted,
                  expression: "isStageCompleted",
                },
              ],
              staticClass: "mile-progress-staging__content",
            },
            [
              _c("img", {
                attrs: {
                  src: _vm.images["completed"],
                  alt: _vm.WORDS.MILE_PROGRESS.COMPLETED,
                },
              }),
            ]
          ),
        ]),
        _c("transition", { attrs: { name: "mile-progress-staging-fade" } }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isStageUp,
                  expression: "isStageUp",
                },
              ],
              staticClass: "mile-progress-staging__content",
            },
            [
              _c("img", {
                attrs: { src: _vm.images["stageup"], alt: _vm.WORDS.STAGE_UP },
              }),
              _c(
                "div",
                { staticClass: "mile-progress-staging__content-button" },
                [
                  _c(
                    "p",
                    { staticClass: "c-lead4 c-lead4--white01 c-lead4--center" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.WORDS.MILE_PROGRESS.COMPLETED_DESCRIPTION
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "Button",
                    {
                      attrs: { "style-type": "quaternary", fluid: "" },
                      on: { click: _vm.onNextStage },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.WORDS.MILE_PROGRESS.NEXT_BUTTON) + " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }